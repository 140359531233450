import React, {useEffect, useState} from 'react';
import classNames from 'classnames';

export default ({
	image = {url: ''},
	title,
	className,
	onAdd,
	onClick,
	disabled,
	addedCount = 0,
	...props
}) => {
	const [mounted, setMounted] = useState(false);
	const [isBouncing, setBouncing] = useState(false);

	useEffect(() => {
		if (!mounted) return;

		let bounceTimeout = setTimeout(() => {
			setBouncing(false);
		}, 1000);

		setBouncing(true);

		return () => {
			clearTimeout(bounceTimeout);
		};
	}, [addedCount]);

	useEffect(() => {
		setMounted(true);
	}, []);

	return (
		<div
			className={classNames('pr', {
				'bundle-tile--bounce': isBouncing,
			})}>
			<button
				{...props}
				onClick={onClick}
				title="Bundle Product"
				aria-label="Bundle Product"
				className={classNames('tl db x', className)}>
				<div className="pr of--hidden mb1 link--trigger">
					<div className="image size--3x4">
						<picture>
							<img
								alt={title}
								src={`${image.url}?w=400&h=533&auto=format`}
							/>
						</picture>
					</div>
					<div className="pen pa fill df fdc jcc aic link--hidden">
						<span className="pea button--primary">Quick View</span>
					</div>
					<div
						className={classNames(
							'bundle-builder__tile-added pa bottom left right bg--black color--white sans--xs tc fw--800',
							{
								active: addedCount > 0,
							},
						)}>
						Added ({addedCount})
					</div>
				</div>
				<p className="fw--800">{title}</p>
			</button>
			<div className="pa top right">
				<button
					disabled={disabled}
					onClick={onAdd}
					title="Add Product"
					aria-label="Add Product"
					className={classNames('db x p1 link--opacity', className)}>
					<picture className="db">
						<img
							alt="Add"
							className="db"
							src="/images/icon-plus-bundle.svg"
						/>
					</picture>
				</button>
			</div>
		</div>
	);
};
