import React, {useState} from 'react';
import {connectToStore} from '../components/Provider';
import BundleBuilder from '../components/BundleBuilder';
import clientSideComponent from '../middleware/clientSideComponent';
import {compose} from 'recompose';
import uniq from 'lodash/uniq';
import find from 'lodash/find';
import without from 'lodash/without';
import {encode} from '../utils/shopify-gid';
import Helmet from 'react-helmet';

const BundleEdit = ({
	isAddingItem,
	bundle,
	savedBundlesById,
	pageContext,
	addItemAndOpenCart,
	addProductsToBundle,
	resetNewBundle,
	replaceBundleProducts,
	removeProductFromBundleByIndex,
	updateBundleTitle,
	saveBundleForLater,
}) => {
	const {
		title,
		modal,
		ageFilters,
		symptomFilters,
		productOptions,
		cart = {},
	} = pageContext;

	const {bulkDiscountMinimumItems, bulkDiscountPercentage} = cart;

	const [activeAgeFilters, setAgeFilters] = useState([]);
	const [activeSymptomFilters, setSymptomFilters] = useState([]);
	const [sortBy, setSortBy] = useState(null);
	const [searchQuery, setSearchQuery] = useState('');
	const [activeSavedBundleId, setSavedBundleId] = useState(null);
	const [showSavedBundleModal, setShowSavedBundleModal] = useState(false);
	const [showProductModal, setShowProductModal] = useState(false);
	const [activeProductModalId, setProductModalId] = useState(null);
	const savedBundle = savedBundlesById[activeSavedBundleId];
	const inventoryProductOptions = productOptions.filter(product => !product.hideLowInventoryProduct);

	const onAddToCart = () => {
		if (!bundle.productIds || !bundle.productIds.length) return;

		const encodedLineItems = bundle.productIds.map(_id => {
			const product = find(inventoryProductOptions, {_id});
			const {variantId, subscription} = product;
			const customAttributes = [];

			if (subscription) {
				customAttributes.push({
					key: '_subscription_options',
					value: JSON.stringify(subscription),
				});
			}

			const encodedVariantId = encode('ProductVariant', variantId, {
				accessToken: process.env.GATSBY_SHOPIFY_STOREFRONT_API_TOKEN,
			});

			return {quantity: 1, variantId: encodedVariantId, customAttributes};
		});

		addItemAndOpenCart(encodedLineItems);
	};

	return (
		<React.Fragment>
			<Helmet title="Edit Medicine Cabinet - Genexa" />
			<a aria-label="Site Map" href="https://www.genexa.com/sitemap.xml" class="full-hide">Sitemap</a> {/* Sitemap link accessibility */}
			<BundleBuilder
				showAutosaveNote
				title={title}
				bulkDiscountMinimumItems={bulkDiscountMinimumItems}
				bulkDiscountPercentage={bulkDiscountPercentage}
				modal={modal}
				bundleTitle={bundle.title}
				productOptions={inventoryProductOptions}
				ageFilterOptions={ageFilters}
				symptomFilterOptions={symptomFilters}
				activeAgeFilters={activeAgeFilters}
				activeSymptomFilters={activeSymptomFilters}
				searchQuery={searchQuery}
				sortBy={sortBy}
				productIds={bundle.productIds}
				showSavedBundleModal={showSavedBundleModal}
				showProductModal={showProductModal}
				savedBundle={savedBundle}
				activeProductModalId={activeProductModalId}
				isAddingToCart={isAddingItem}
				onAddProduct={productId => {
					addProductsToBundle(bundle.bundleId, [productId]);
				}}
				onRemoveProduct={index =>
					removeProductFromBundleByIndex(bundle.bundleId, index)
				}
				onContinueBundling={() => {
					setShowSavedBundleModal(false);
				}}
				onAddToCart={onAddToCart}
				onSaveForLater={() => {
					const bundleId = saveBundleForLater(
						bundle.title,
						bundle.productIds,
					);
					setSavedBundleId(bundleId);
					setShowSavedBundleModal(true);
				}}
				onSaveTitle={(title, bundleId = bundle.bundleId) =>
					updateBundleTitle(bundleId, title)
				}
				onClickProductModal={productId => {
					setProductModalId(productId);
					setShowProductModal(true);
				}}
				onCloseProductModal={() => setShowProductModal(false)}
				onChangeSearchQuery={query => setSearchQuery(query)}
				onAddAgeFilter={filter => {
					setAgeFilters(uniq([...activeAgeFilters, filter]));
				}}
				onRemoveAgeFilter={filter => {
					setAgeFilters(without(activeAgeFilters, filter));
				}}
				onAddSymptomFilter={filter => {
					setSymptomFilters(uniq([...activeSymptomFilters, filter]));
				}}
				onRemoveSymptomFilter={filter => {
					setSymptomFilters(without(activeSymptomFilters, filter));
				}}
				onClearFilters={() => {
					setAgeFilters([]);
					setSymptomFilters([]);
				}}
				onChangeSort={sortBy => setSortBy(sortBy)}
			/>
		</React.Fragment>
	);
};

const mapStateToProps = ({savedBundlesById, isAddingItem}, {bundleId}) => ({
	isAddingItem,
	bundle: savedBundlesById[bundleId],
	savedBundlesById,
});

const mapStoreToProps = ({
	addItemAndOpenCart,
	addProductsToBundle,
	replaceBundleProducts,
	removeProductFromBundleByIndex,
	updateBundleTitle,
	saveBundleForLater,
	resetNewBundle,
}) => ({
	addItemAndOpenCart,
	addProductsToBundle,
	replaceBundleProducts,
	removeProductFromBundleByIndex,
	updateBundleTitle,
	saveBundleForLater,
	resetNewBundle,
});

export default compose(
	clientSideComponent,
	connectToStore(mapStateToProps, mapStoreToProps),
)(BundleEdit);
