import React from 'react';

export const EmptyTileCompressed = props => (
	<div {...props}>
		<div className="pr mb1">
			<div className="bg--gray-bg image size--1x1" />
			<div className="pa fill df fdc jcc aic">
				<picture className="db">
					<img
						alt="Add"
						className="db"
						src="/images/icon-plus-bundle.svg"
					/>
				</picture>
			</div>
		</div>
		<p className="sans--xs fw--800 bundle-builder__tile-title" />
	</div>
);

export default ({image = {url: ''}, title, ...props}) => (
	<div {...props}>
		<div className="bg--gray-bg image size--1x1 mb1">
			<picture>
				<img alt={title} src={`${image.url}?w=400&h=400&auto=format`} />
			</picture>
		</div>
		<p className="sans--xs fw--800 bundle-builder__tile-title">{title}</p>
	</div>
);
