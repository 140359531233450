import React, {useState, useRef, useEffect} from 'react';
import classNames from 'classnames';
import Drawer from './Drawer';
import ProgressBar from './ProgressBar';
import InlineForm from './InlineForm';
import {Link} from 'gatsby';
import ProductTileBundle from './ProductTileBundle';
import ProductTileBundleCompressed, {
	EmptyTileCompressed,
} from './ProductTileBundleCompressed';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import find from 'lodash/find';
import _sortBy from 'lodash/sortBy';
import isTouchEnabled from '../utils/isTouchEnabled';
import ProductTileBundleExpanded from './ProductTileBundleExpanded';
import {Transition} from 'react-transition-group';
import fuzzysearch from 'fuzzysearch';
import Select from './Select';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';
import BlockContent from '@sanity/block-content-to-react';
import richText from '../serializers/richText';
import Flickity from './Flickity';
import Reveal from './Reveal';

//
// === Saved Bundle Modal ===
//

const SavedBundleModal = ({
	bundleId,
	title,
	color,
	products,
	totalCents,
	onContinueBundling,
	onSaveTitle,
}) => {
	const [showTitleInput, setShowTitleInput] = useState(false);

	const titleTrigger = !showTitleInput && (
		<button
		title="Save Bundle" aria-label="Save Bundle"
			onClick={() => setShowTitleInput(true)}
			className="db sans--md sans--lg--lg fw--800">
			{title}
		</button>
	);

	const titleForm = showTitleInput && (
		<InlineForm
			initialValue={title}
			onSubmit={title => {
				setShowTitleInput(false);
				onSaveTitle(title);
			}}
			submitCta="Save"
			placeholder="Your Medicine Cabinet"
		/>
	);

	return (
		<div>
			<h3 className="fw--800 mb1">Cabinet Saved For Later</h3>

			<div className="hide--md mb2">
				{titleTrigger}
				{titleForm}
			</div>

			<div className="row bundle-builder__modal-row fdr mb2">
				<div className="col c6">
					<div
						className="y of--hidden df jcc aic pt5 pb5 pt10--lg pb10--lg"
						style={{backgroundColor: color}}>
						<img
							className="bundle-builder__modal-tile-logo db"
							src="/images/logo-tile.png"
							alt="Logo"
						/>
					</div>
				</div>
				<div className="col c6 bundle-builder__modal-col fdc jcb">
					<div>
						<div className="show--md mb2">
							{titleTrigger}
							{titleForm}
						</div>
						<p className="mb1">
							{centsToPriceNoTrailingZeros(totalCents)}
						</p>
						<ul className="mb1 sans--xs bundle-builder__modal-product-list">
							{products.map((product, index) => (
								<li key={index}>{product.title}</li>
							))}
						</ul>
					</div>

					<div>
						<Link
							aria-label="Edit"
							to={`/cabinets/${bundleId}`}
							className="link--underline-invert-small">
							Edit
						</Link>
					</div>
				</div>
			</div>

			<div className="row ">
				<div className="col c6--md mb1 mb0--md">
					<Link aria-label="View Saved Cabinets" to="/cabinets/saved" className="db x button--primary">
						View Saved Cabinets
					</Link>
				</div>
				<div className="col c6--md">
					<button
						title="Continue Bundling" aria-label="Continue Bundling"
						onClick={onContinueBundling}
						className="db x button--primary">
						Continue Bundling
					</button>
				</div>
			</div>
		</div>
	);
};

//
// === Product Modal ===
//

const SLIDE_OPTIONS = {
	cellAlign: 'center',
	contain: false,
	wrapAround: true,
	prevNextButtons: false,
	pageDots: false,
	selectedAttraction: 0.1,
	friction: 0.8,
};

const ProductModal = ({
	title,
	drugName,
	activeIngredients,
	description = [],
	heroImages = [],
	slug,
	isActive,
	onAdd = () => {},
	onRemove = () => {},
}) => {
	const [activeImageIndex, setImageIndex] = useState(0);
	const activeImage = heroImages[activeImageIndex];

	const titleSection = (
		<div>
			<h2 className="serif--lg serif--xxl--lg mb1">{title}</h2>
			<p className="fw--800">{drugName}</p>
			<p>{activeIngredients}</p>
		</div>
	);

	const productLink = (
		<p>
			<Link
				aria-label="View Full Details"
				className="link--underline-invert-small"
				to={`/products/${slug}`}>
				View Full Details
			</Link>
		</p>
	);

	const imageTiles = (
		<div className="row">
			<div className="col c3">
				<div className="pl2--xl">
					{heroImages.map((image, index) => (
						<button
							title={image.alt}
							aria-label={image.alt}
							key={index}
							className="db x link--opacity mb1"
							onClick={() => setImageIndex(index)}>
							<div className="x image size--3x4">
								<picture>
									<img
										alt={image.alt}
										src={`${image.url}?w=100&auto=format`}
									/>
								</picture>
							</div>
						</button>
					))}
				</div>
			</div>
			<div className="col c9">
				<div className="image size--3x4">
					<picture>
						<source
							srcSet={`${activeImage.url}?w=900&auto=format&q=75`}
							media="(min-width: 1000px)"
						/>
						<source
							srcSet={`${activeImage.url}?w=600&auto=format&q=75`}
							media="(min-width: 600px)"
						/>
						<img
							alt={activeImage.alt}
							src={`${activeImage.url}?w=400&auto=format`}
						/>
					</picture>
				</div>
			</div>
		</div>
	);

	const imageCarousel = (
		<Flickity options={SLIDE_OPTIONS}>
			{heroImages.map((image, index) => (
				<div
					key={image._key}
					className="bundle-builder__modal-slide mr2">
					<div className="image size--3x4">
						<picture>
							<source
								srcSet={`${image.url}?w=900&auto=format&q=75`}
								media="(min-width: 1000px)"
							/>
							<source
								srcSet={`${image.url}?w=600&auto=format&q=75`}
								media="(min-width: 600px)"
							/>
							<img
								alt={image.alt}
								src={`${image.url}?w=400&auto=format`}
							/>
						</picture>
					</div>
				</div>
			))}
		</Flickity>
	);

	return (
		<Reveal>
			<div className="row bundle-builder__modal-row fdc fdr--md">
				<div className="col c6--md">
					<div className="hide--md mb2 reveal__slide reveal__delay--1">
						{titleSection}
						<div className="hide--md mt2">{productLink}</div>
					</div>
					<div className="show--md reveal__slide reveal__delay--2">
						{imageTiles}
					</div>
					<div className="hide--md bundle-builder__modal-container reveal__slide reveal__delay--2">
						{imageCarousel}
					</div>
				</div>
				<div className="col c6--md bundle-builder__modal-col fdc jcb reveal__slide reveal__delay--3">
					<div>
						<div className="show--md">{titleSection}</div>
						<div className="show--md mt2 rich-text line-break">
							<BlockContent
								blocks={description}
								serializers={richText}
							/>
						</div>
						<div className="show--md mt2">{productLink}</div>
					</div>
					<div className="mt2">
						{!isActive && (
							<button
								title="Add Bundle"
								aria-label="Add Bundle"
								className="button--primary tl x"
								onClick={onAdd}>
								<div className="df fdr jcb">
									<span>Add to Bundle</span>
									<img
										className="db"
										src="/images/icon-plus-invert.svg"
										alt="Add Bundle"
									/>
								</div>
							</button>
						)}
						{!!isActive && (
							<button
								title="Remove from Bundle"
								aria-label="Remove from Bundle"
								className="button--primary tl x"
								onClick={onRemove}>
								<div className="df fdr jcb">
									<span>Remove from Bundle</span>
									<img
										className="db"
										src="/images/icon-minus-invert.svg"
										alt="Remove Bundle"
									/>
								</div>
							</button>
						)}
					</div>
				</div>
			</div>
		</Reveal>
	);
};

//
// === Bundle Builder ===
//

const SORT_OPTIONS = [
	{
		title: 'Featured',
		value: null,
	},
	{
		title: 'Price: High to Low',
		value: 'highLow',
	},
	{
		title: 'Price: Low to High',
		value: 'lowHigh',
	},
];

const TRUNCATED_SYMPTOM_LENGTH = 10;
const MAX_PRODUCTS = 6;
export const FOOTER_TRANSITION_DURATION = 400;
export const MODAL_TRANSITION_DURATION = 800;

const FOOTER_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${FOOTER_TRANSITION_DURATION}ms ease, transform ${FOOTER_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
		position: 'absolute',
		top: 0,
		left: 0,
		right: 0,
	},
	exited: {
		opacity: 0,
	},
};

const UNDERLAY_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
	},
	entered: {
		opacity: 1,
	},
	exiting: {
		opacity: 0,
	},
	exited: {
		opacity: 0,
	},
};

const MODAL_TRANSITION_STYLES = {
	default: {
		transition: `opacity ${MODAL_TRANSITION_DURATION}ms ease, transform ${MODAL_TRANSITION_DURATION}ms cubic-bezier(0, 0, 0.25, 1)`,
	},
	entering: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	entered: {
		opacity: 1,
		transform: 'translate(-50%, -50%)',
	},
	exiting: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
	exited: {
		opacity: 0,
		transform: 'translate(-50%, calc(-50% + 100px))',
	},
};

export default ({
	title,
	bulkDiscountPercentage,
	bulkDiscountMinimumItems,
	showIntroModal,
	setIntroModal,
	showPrefillModal,
	setPrefillModal,
	showAutosaveNote,
	prefillModal,
	modal,
	productOptions = [],
	ageFilterOptions = [],
	symptomFilterOptions = [],
	showSavedBundleModal,
	showProductModal,
	savedBundle,
	bundleTitle,
	productIds = [],
	searchQuery,
	sortBy,
	activeAgeFilters = [],
	activeSymptomFilters = [],
	activeProductModalId,
	isAddingToCart,
	onAddProduct = () => {},
	onRemoveProduct = () => {},
	onContinueBundling = () => {},
	onAddToCart = () => {},
	onSaveForLater = () => {},
	onSaveTitle = () => {},
	onClickProductModal = () => {},
	onCloseProductModal = () => {},
	onChangeSearchQuery = () => {},
	onAddAgeFilter = () => {},
	onRemoveAgeFilter = () => {},
	onAddSymptomFilter = () => {},
	onRemoveSymptomFilter = () => {},
	onClearFilters = () => {},
	onChangeSort = () => {},
}) => {
	const isTouchDevice = isTouchEnabled();
	const condensedEl = useRef(null);
	const expandedEl = useRef(null);
	const [showTitleInput, setShowTitleInput] = useState(false);
	const [footerHeight, setFooterHeight] = useState(null);
	const [isFooterExpanded, setFooterExpanded] = useState(false);
	const [isFilterOpen, updateFilterOpen] = useState(false);
	const [showAllSymptoms, updateShowAllSymptoms] = useState(false);
	const sortedSymptomFilterOptions = _sortBy(symptomFilterOptions, 'title');
	const truncatedSymptomFilters = showAllSymptoms
		? sortedSymptomFilterOptions
		: sortedSymptomFilterOptions.slice(0, TRUNCATED_SYMPTOM_LENGTH);
	const products = productIds.map(_id => find(productOptions, {_id}));
	const totalCents = reduce(
		products,
		(totalCents, product) => {
			if (!product) return totalCents;

			if (products.length >= bulkDiscountMinimumItems) {
				const discountPercentage = (100 - bulkDiscountPercentage) / 100;
				return totalCents + product.cents * discountPercentage;
			} else {
				return totalCents + product.cents;
			}
		},
		0,
	);
	const activeModalProduct = find(productOptions, {
		_id: activeProductModalId,
	});

	// Resize callback
	const onResize = () => {
		if (isFooterExpanded) {
			expandedEl.current &&
				setFooterHeight(
					expandedEl.current.getBoundingClientRect().height,
				);
		} else {
			condensedEl.current &&
				setFooterHeight(
					condensedEl.current.getBoundingClientRect().height,
				);
		}
	};

	// Resize height on load/resize
	useEffect(() => {
		window.addEventListener('resize', onResize);
		window.addEventListener('load', onResize);

		return () => {
			window.removeEventListener('load', onResize);
			window.removeEventListener('resize', onResize);
		};
	}, []);

	// Resize height on open change
	useEffect(onResize, [isFooterExpanded]);

	const filteredProductOptions = filter(productOptions, product => {
		const {ages = [], symptoms = []} = product;

		let isAgeMatch = false;
		let isSymptomMatch = false;
		let isSearchMatch = false;

		if (activeAgeFilters.length) {
			for (let i = 0; i < activeAgeFilters.length; i++) {
				if (ages.indexOf(activeAgeFilters[i]) > -1) {
					isAgeMatch = true;
				}
			}
		} else {
			isAgeMatch = true;
		}

		if (activeSymptomFilters.length) {
			for (let i = 0; i < activeSymptomFilters.length; i++) {
				if (symptoms.indexOf(activeSymptomFilters[i]) > -1) {
					isSymptomMatch = true;
				}
			}
		} else {
			isSymptomMatch = true;
		}

		if (searchQuery) {
			isSearchMatch = fuzzysearch(
				searchQuery.toLowerCase().trim(),
				product.title.toLowerCase().trim(),
			);
		} else {
			isSearchMatch = true;
		}

		return isAgeMatch && isSymptomMatch && isSearchMatch;
	});

	let sortedProductOptions = [...filteredProductOptions];

	if (sortBy === 'lowHigh') {
		sortedProductOptions = _sortBy(sortedProductOptions, 'cents');
	} else if (sortBy === 'highLow') {
		sortedProductOptions = _sortBy(sortedProductOptions, 'cents').reverse();
	}

	//
	// === Builder Layout ===
	//

	const clearFiltersButton = (
		<button
			title="Clear filters"
			aria-label="Clear filters"
			onClick={onClearFilters}
			className="link--opacity-invert link--underline-invert-small sans--xs">
			Clear filters
		</button>
	);

	const ageFilters = (
		<Drawer
			openByDefault
			renderTrigger={({onClick, isOpen}) => (
				<button title="Age Filters" aria-label="Age Filters" className="fw--800" onClick={onClick}>
					Age
					<img
						className={classNames('drawer__arrow-flip ml1', {
							active: isOpen,
						})}
						src="/images/icon-arrow-drawer.svg"
						alt="arrow"
					/>
				</button>
			)}>
			<div className="pt2 df fdr">
				{ageFilterOptions.map((filter, index) => {
					const isActive = activeAgeFilters.indexOf(filter._id) > -1;
					const onClick = isActive
						? () => onRemoveAgeFilter(filter._id)
						: () => onAddAgeFilter(filter._id);

					return (
						<div key={index} className="mr1 mb1">
							<button
								title={filter.title}
								aria-label={filter.title}
								onClick={onClick}
								className={classNames('db button--primary', {
									invert: !isActive,
								})}>
								{filter.title}
							</button>
						</div>
					);
				})}
			</div>
		</Drawer>
	);

	const symptomFilters = (
		<Drawer
			openByDefault
			renderTrigger={({onClick, isOpen}) => (
				<button title="Symptom" aria-label="Symptom" className="fw--800" onClick={onClick}>
					Symptom
					<img
						className={classNames('drawer__arrow-flip ml1', {
							active: isOpen,
						})}
						src="/images/icon-arrow-drawer.svg"
						alt="arrow"
					/>
				</button>
			)}>
			<div className="pt2 df fdr jcs aic fw">
				{truncatedSymptomFilters.map((filter, index) => {
					const isActive =
						activeSymptomFilters.indexOf(filter._id) > -1;
					const onClick = isActive
						? () => onRemoveSymptomFilter(filter._id)
						: () => onAddSymptomFilter(filter._id);

					return (
						<div key={index} className="mr1 mb1">
							<button
								title={filter.title}
								ria-label={filter.title}
								onClick={onClick}
								style={{
									'--backgroundColor': filter.color,
								}}
								className={classNames(
									'db button--pill--filter quiz-bubbles',
									{
										active: isActive,
									},
								)}>
								<span>{filter.title}</span>
							</button>
						</div>
					);
				})}
				{!showAllSymptoms && (
					<button
						title="Add Symptom"
						aria-label="Add Symptom"
						onClick={() => updateShowAllSymptoms(true)}
						className="link--opacity db mr1 mb1">
						<img
							className="db"
							src="/images/icon-symptom-more.svg"
							alt="Add Symptom"
						/>
					</button>
				)}
			</div>
		</Drawer>
	);

	const productResults =
		sortedProductOptions.length > 0 ? (
			<div className="row gutter--vertical">
				{sortedProductOptions.map((product, index) => (
					<div key={index} className="col c6 c3--xl">
						<ProductTileBundle
							title={product.title}
							image={product.bundleTileImage}
							disabled={productIds.length >= MAX_PRODUCTS}
							onClick={() => onClickProductModal(product._id)}
							onAdd={() => onAddProduct(product._id)}
							addedCount={reduce(
								productIds,
								(count, id) => {
									if (id === product._id) {
										return count + 1;
									} else {
										return count;
									}
								},
								0,
							)}
						/>
					</div>
				))}
			</div>
		) : (
			<p className="serif--lg">No results.</p>
		);

	const productSort = (
		<Select
			label="Sort by"
			value={sortBy}
			dropdownAlignment="top right"
			dropdownClassName="bundle-builder__sort-dropdown"
			options={SORT_OPTIONS}
			onChange={onChangeSort}
		/>
	);

	const filterToggle = (
		<button
			title="Filter"
			aria-label="Filter Button"
			className="fw--800"
			onClick={() =>
				isFilterOpen ? updateFilterOpen(false) : updateFilterOpen(true)
			}>
			Filter
			<img
				className={classNames('drawer__arrow-flip ml1', {
					active: isFilterOpen,
				})}
				src="/images/icon-arrow-drawer.svg"
				alt="arrow"
			/>
		</button>
	);

	const productGrid = (
		<div className="bg--gray-bg bundle-builder__product-grid y">
			<div className="show--lg row mb3 align--middle">
				<div className="col c6">
					<InlineForm
						className="invert"
						placeholder="Find a product"
						submitCta="Search"
						onSubmit={searchQuery =>
							onChangeSearchQuery(searchQuery)
						}
					/>
				</div>
				<div className="col c6">
					<div className="df fdr jce">{productSort}</div>
				</div>
			</div>
			{productResults}
		</div>
	);

	const builderLayout = (
		<div className="grid-container contained mt3 mt0--lg">
			<div className="row bundle-builder__row">
				<div className="col c5--lg pt3--lg mb2 mb0--lg">
					<h1 className="serif--lg serif--xxl--lg mb2 mr2">
						{title}
					</h1>
					<div className="show--lg">
						<div className="mb3">{clearFiltersButton}</div>
						<div className="mb3">{ageFilters}</div>
						<hr className="mb4" />
						{symptomFilters}
					</div>
					<div className="hide--lg">
						<div className="df fdr jcb">
							{filterToggle}
							{productSort}
						</div>
						<Drawer
							isOpen={isFilterOpen}
							openByDefault
							renderTrigger={() => null}>
							<div className="pt2 pb2">
								<div className="mb3">{clearFiltersButton}</div>
								<div className="mb3">{ageFilters}</div>
								<hr className="mb4" />
								{symptomFilters}
								<div className="mt2">
									<button
										title="Apply Filters"
										aria-label="Apply Filters"
										onClick={() => updateFilterOpen(false)}
										className="button--primary db x">
										Apply Filters
									</button>
								</div>
							</div>
						</Drawer>
					</div>
				</div>
				<div className="col c7--lg">{productGrid}</div>
			</div>
		</div>
	);

	//
	// === Footer Layout ===
	//

	const tileIndexes = Array.apply(null, Array(MAX_PRODUCTS)).map(
		(item, index) => index,
	);

	const addToCartButton = (
		<button
			title="Complete Checkout"
			aria-label="Complete Checkout"
			disabled={isAddingToCart || productIds.length === 0}
			onClick={onAddToCart}
			className={classNames(
				'button--primary bundle-builder__add-to-cart',
				{
					loading: isAddingToCart,
				},
			)}>
			Complete Checkout
			{!!totalCents && (
				<span> - {centsToPriceNoTrailingZeros(totalCents)}</span>
			)}
		</button>
	);

	const condensedFooterLayout = (
		<div className="grid-container container pt2 pb2">
			<div className="row">
				<div className="col c5--lg">
					<p className="fw--800 mb2--lg">{bundleTitle}</p>

					<div className="mb3 show--lg">
						<div className="mb1 pr3--lg">
							<ProgressBar
								percentage={
									productIds.length / bulkDiscountMinimumItems
								}
							/>
						</div>
						<p className="sans--sm sans--md--xl">
							{productIds.length >= bulkDiscountMinimumItems ? (
								<span>
									You save an{' '}
									<span className="fw--800">
										extra {bulkDiscountPercentage}%
									</span>{' '}
									at checkout!
								</span>
							) : (
								<span>
									Add{' '}
									<span className="fw--800">
										{bulkDiscountMinimumItems -
											productIds.length}{' '}
										more
									</span>{' '}
									items for{' '}
									<span className="fw--800">
										{bulkDiscountPercentage}% off
									</span>
								</span>
							)}
						</p>
					</div>

					<div className="df fdr show--lg">{addToCartButton}</div>
				</div>
				<div className="col c6--lg show--lg">
					<div className="row gutter--small gutter--desktop--lg">
						{tileIndexes.map(index => {
							const product = products[index];

							const tile = product ? (
								<ProductTileBundleCompressed
									image={product.bundleCondensedImage}
									title={product.title}
									onClick={() => onRemoveProduct(index)}
								/>
							) : (
								<EmptyTileCompressed />
							);

							return (
								<div key={index} className="col c2">
									{tile}
								</div>
							);
						})}
					</div>
				</div>
			</div>
			<div className="hide--lg mt1 show--lg">{addToCartButton}</div>
		</div>
	);

	const expandedFooterLayout = (
		<div className="grid-container container pt2 pb3">
			<div className="row mb1">
				<div className="col c5--lg mb2 mb0--lg">
					{!showTitleInput && (
						<button
							title={bundleTitle}
							aria-label={bundleTitle}
							onClick={() => setShowTitleInput(true)}
							className="db sans--md sans--lg--lg fw--800">
							{bundleTitle}
						</button>
					)}

					{showTitleInput && (
						<div className="mb1">
							<div className="show--lg">
								<InlineForm
									initialValue={bundleTitle}
									onSubmit={title => {
										setShowTitleInput(false);
										onSaveTitle(title);
									}}
									submitCta="Save"
									placeholder="Your Medicine Cabinet"
									className="invert"
								/>
							</div>
							<div className="hide--lg z1 pr">
								<InlineForm
									initialValue={bundleTitle}
									onSubmit={title => {
										setShowTitleInput(false);
										onSaveTitle(title);
									}}
									submitCta="Save"
									placeholder="Your Medicine Cabinet"
								/>
							</div>
						</div>
					)}

					<div className="df fdr mb3">
						{showAutosaveNote ? (
							<p className="sans--xs o5 mr1">
								Changes are auto saved.
							</p>
						) : (
							<button
								title="Save For Later"
								aria-label="Save For Later"
								onClick={onSaveForLater}
								className="sans--xs link--underline-invert-small db mr1">
								Save For Later
							</button>
						)}
						<Link
							aria-label="View Saved Cabinets"
							to="/cabinets/saved"
							className="sans--xs link--underline-invert-small db mr1">
							View Saved Cabinets
						</Link>
					</div>

					<div className="mb3">
						<div className="mb1 pr3--lg">
							<ProgressBar
								percentage={
									productIds.length / bulkDiscountMinimumItems
								}
							/>
						</div>
						<p className="sans--sm sans--md--xl">
							{productIds.length >= bulkDiscountMinimumItems ? (
								<span>
									You save an{' '}
									<span className="fw--800">
										extra {bulkDiscountPercentage}%
									</span>{' '}
									at checkout!
								</span>
							) : (
								<span>
									Add{' '}
									<span className="fw--800">
										{bulkDiscountMinimumItems -
											productIds.length}{' '}
										more
									</span>{' '}
									items for{' '}
									<span className="fw--800">
										{bulkDiscountPercentage}% off
									</span>
								</span>
							)}
						</p>
					</div>

					<div className="df fdr show--lg">{addToCartButton}</div>
				</div>
				<div className="col c6--lg">
					<div className="row gutter--small hide--lg">
						{tileIndexes.map(index => {
							const product = products[index];

							const tile = product ? (
								<div className="mb1">
									<ProductTileBundleCompressed
										image={product.bundleCondensedImage}
										title={product.title}
										onClick={() =>
											onClickProductModal(product._id)
										}
									/>
								</div>
							) : (
								<EmptyTileCompressed />
							);

							return (
								<div key={index} className="col c4 c2--md">
									{tile}
								</div>
							);
						})}
					</div>

					<div className="pt2 pb2 show--lg">
						<div className="pr bundle-builder__expanded-tile-height">
							<img
								className="bundle-builder__shelf pen z0"
								src="/images/bundle-shelf.png"
								alt="Bundle Shelf"
							/>
							<div className="row push-up">
								{tileIndexes.map(index => {
									const product = products[index];

									if (!product) return null;

									return (
										<div key={index} className="col c2">
											<ProductTileBundleExpanded
												className="db mxa"
												image={
													product.bundleExpandedImage
												}
												title={product.title}
												onClick={() =>
													onRemoveProduct(index)
												}
											/>
										</div>
									);
								})}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className="hide--lg">{addToCartButton}</div>
		</div>
	);

	const expandToggle = isTouchDevice && (
		<div className="db pa top right grid-container contained pt2 pen">
			<button
				title="Expand Toggle"
				aria-label="Expand Toggle Button"
				className="fw--800 pea"
				onClick={() => setFooterExpanded(!isFooterExpanded)}>
				{isFooterExpanded ? 'Collapse' : 'Expand'}
				<img
					className={classNames('drawer__arrow-flip ml1', {
						active: !isFooterExpanded,
					})}
					src="/images/icon-arrow-drawer.svg"
					alt="arrow"
				/>
			</button>
		</div>
	);

	const footerUnderlay = (
		<Transition
			in={isFooterExpanded}
			mountOnEnter
			unmountOnExit
			timeout={MODAL_TRANSITION_DURATION}>
			{status => (
				<div
					onClick={() => setFooterExpanded(null)}
					className="bundle-builder__footer-underlay pf fill hide--lg"
					style={{
						...UNDERLAY_TRANSITION_STYLES.default,
						...UNDERLAY_TRANSITION_STYLES[status],
					}}
				/>
			)}
		</Transition>
	);

	const footer = (
		<div
			onMouseLeave={
				!isTouchDevice ? () => setFooterExpanded(false) : null
			}
			onMouseEnter={!isTouchDevice ? () => setFooterExpanded(true) : null}
			className={classNames(
				'bundle-builder__footer pf bottom left right',
				{
					active: isFooterExpanded,
				},
			)}>
			<div
				className="pr"
				style={{
					height: footerHeight,
					transition: 'height 400ms cubic-bezier(.12,.67,.53,1)',
				}}>
				<Transition
					in={!isFooterExpanded}
					mountOnEnter
					unmountOnExit
					timeout={FOOTER_TRANSITION_DURATION}>
					{status => (
						<div
							ref={condensedEl}
							style={{
								...FOOTER_TRANSITION_STYLES.default,
								...FOOTER_TRANSITION_STYLES[status],
							}}>
							{condensedFooterLayout}
						</div>
					)}
				</Transition>
				<Transition
					in={isFooterExpanded}
					mountOnEnter
					unmountOnExit
					timeout={FOOTER_TRANSITION_DURATION}>
					{status => (
						<div
							ref={expandedEl}
							style={{
								...FOOTER_TRANSITION_STYLES.default,
								...FOOTER_TRANSITION_STYLES[status],
							}}>
							{expandedFooterLayout}
						</div>
					)}
				</Transition>
			</div>
			{expandToggle}
		</div>
	);

	//
	// === Saved for later ===
	//

	const savedBundleProducts = savedBundle
		? savedBundle.productIds.map(_id => find(productOptions, {_id}))
		: [];
	const savedBundleCents = savedBundle
		? reduce(
				savedBundleProducts,
				(totalCents, product) => {
					if (products.length >= MAX_PRODUCTS) {
						const discountPercentage =
							(100 - bulkDiscountPercentage) / 100;
						return totalCents + product.cents * discountPercentage;
					} else {
						return totalCents + product.cents;
					}
				},
				0,
		  )
		: 0;

	const savedModalContents = !!savedBundle && (
		<SavedBundleModal
			{...savedBundle}
			products={savedBundleProducts}
			totalCents={savedBundleCents}
			onContinueBundling={onContinueBundling}
			onSaveTitle={title => onSaveTitle(title, savedBundle.bundleId)}
		/>
	);

	const savedModal = (
		<Transition
			in={showSavedBundleModal}
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<React.Fragment>
					<div
						className="bundle-builder__underlay pf fill"
						style={{
							...UNDERLAY_TRANSITION_STYLES.default,
							...UNDERLAY_TRANSITION_STYLES[status],
						}}
					/>
					<div
						className="bundle-builder__modal pf p2 bg--white"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							...MODAL_TRANSITION_STYLES[status],
						}}>
						{savedModalContents}
						<div className="pa top right p2 pen">
							<button
								title="Close"
								aria-label="Close"
								onClick={onContinueBundling}
								className="db link--opacity pea">
								<img
									className="db"
									src="/images/icon-modal-close.svg"
									alt="Close"
								/>
							</button>
						</div>
					</div>
				</React.Fragment>
			)}
		</Transition>
	);

	const productModalContents = !!activeModalProduct && (
		<ProductModal
			{...activeModalProduct}
			isActive={productIds.indexOf(activeProductModalId) > -1}
			onAdd={() => {
				onAddProduct(activeModalProduct._id);
				onCloseProductModal();
			}}
			onRemove={() => {
				onRemoveProduct(productIds.indexOf(activeProductModalId));
				onCloseProductModal();
			}}
		/>
	);

	const productModal = (
		<Transition
			in={showProductModal}
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<React.Fragment>
					<div
						className="bundle-builder__underlay pf fill"
						style={{
							...UNDERLAY_TRANSITION_STYLES.default,
							...UNDERLAY_TRANSITION_STYLES[status],
						}}
						onClick={onCloseProductModal}
					/>
					<div
						className="bundle-builder__modal--product pf p2 pt4 p4--md p6--lg bg--white"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							...MODAL_TRANSITION_STYLES[status],
						}}>
						{productModalContents}
						<div className="pa top right p2 pen">
							<button
								title="Close"
								aria-label="Close"
								onClick={onCloseProductModal}
								className="db link--opacity pea">
								<img
									className="db"
									src="/images/icon-modal-close.svg"
									alt="Close"
								/>
							</button>
						</div>
					</div>
				</React.Fragment>
			)}
		</Transition>
	);

	const introModal = (
		<Transition
			in={showIntroModal}
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<React.Fragment>
					<div
						className="bundle-builder__underlay pf fill"
						style={{
							...UNDERLAY_TRANSITION_STYLES.default,
							...UNDERLAY_TRANSITION_STYLES[status],
						}}
						onClick={() => setIntroModal(false)}
					/>
					<div
						className="bundle-builder__modal--intro pf bg--white"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							...MODAL_TRANSITION_STYLES[status],
						}}>
						<div className="row gutter--none bundle-builder__modal-row fdr">
							<div className="col c6 show--md bundle-builder__modal-col fdc image">
								<picture>
									<source
										srcSet={`${modal.image.url}?w=900&auto=format&q=75`}
										media="(min-width: 1000px)"
									/>
									<source
										srcSet={`${modal.image.url}?w=600&auto=format&q=75`}
										media="(min-width: 600px)"
									/>
									<img
										alt={modal.image.alt}
										src={`${modal.image.url}?w=400&auto=format`}
									/>
								</picture>
							</div>
							<div className="col c6--md bundle-builder__modal-col fdc">
								<div className="p2 p4--md pt6--lg pb6--lg fdc jcb">
									<div>
										<h2 className="serif--lg serif--xxl--lg mb1 mr3 mr0--md">
											{modal.title}
										</h2>
										<div className="rich-text line-break">
											<BlockContent
												blocks={modal.description}
												serializers={richText}
											/>
										</div>
									</div>
									<div className="mt2 mt4--md">
										<button
											title={modal.closeCta}
											aria-label={modal.closeCta}
											onClick={() => setIntroModal(false)}
											className="db x button--primary">
											{modal.closeCta}
										</button>
									</div>
								</div>
							</div>
						</div>
						<div className="pa top right p2 pen">
							<button
								title="Close"
								aria-label="Close"
								onClick={() => setIntroModal(false)}
								className="db link--opacity pea">
								<img
									className="db"
									src="/images/icon-modal-close.svg"
									alt="Close"
								/>
							</button>
						</div>
					</div>
				</React.Fragment>
			)}
		</Transition>
	);

	const prefillIntroModal = (
		<Transition
			in={showPrefillModal}
			mountOnEnter
			unmountOnExit
			timeout={{
				enter: 100,
				exit: MODAL_TRANSITION_DURATION,
			}}>
			{status => (
				<React.Fragment>
					<div
						className="bundle-builder__underlay pf fill"
						style={{
							...UNDERLAY_TRANSITION_STYLES.default,
							...UNDERLAY_TRANSITION_STYLES[status],
						}}
						onClick={() => setPrefillModal(false)}
					/>
					<div
						className="bundle-builder__modal--intro pf bg--white"
						style={{
							...MODAL_TRANSITION_STYLES.default,
							...MODAL_TRANSITION_STYLES[status],
						}}>
						<div className="row gutter--none bundle-builder__modal-row fdr">
							<div className="col c6 show--md bundle-builder__modal-col fdc image">
								<picture>
									<source
										srcSet={`${prefillModal.image.url}?w=900&auto=format&q=75`}
										media="(min-width: 1000px)"
									/>
									<source
										srcSet={`${prefillModal.image.url}?w=600&auto=format&q=75`}
										media="(min-width: 600px)"
									/>
									<img
										alt={prefillModal.image.alt}
										src={`${prefillModal.image.url}?w=400&auto=format`}
									/>
								</picture>
							</div>
							<div className="col c6--md bundle-builder__modal-col fdc">
								<div className="p2 p4--md pt6--lg pb6--lg pt10--xl pb10--xl fdc jcb">
									<div>
										<h2 className="serif--lg serif--xxl--lg mb1 mr3 mr0--md">
											{prefillModal.title}
										</h2>
										<div className="rich-text line-break">
											<BlockContent
												blocks={
													prefillModal.description
												}
												serializers={richText}
											/>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="pa top right p2 pen">
							<button
								title="Close"
								aria-label="Close"
								onClick={() => setPrefillModal(false)}
								className="db link--opacity pea">
								<img
									className="db"
									src="/images/icon-modal-close.svg"
									alt="Close"
								/>
							</button>
						</div>
					</div>
				</React.Fragment>
			)}
		</Transition>
	);

	return (
		<div className="of--hidden pb8 mb12">
			{builderLayout}
			{footerUnderlay}
			{footer}
			{savedModal}
			{productModal}
			{introModal}
			{prefillIntroModal}
		</div>
	);
};
