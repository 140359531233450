import React from 'react';
import {connectToStore} from '../components/Provider';
import {Link} from 'gatsby';
import clientSideComponent from '../middleware/clientSideComponent';
import {compose} from 'recompose';
import find from 'lodash/find';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
import reduce from 'lodash/reduce';
import filter from 'lodash/filter';
import BundleTile from '../components/BundleTile';
import Helmet from 'react-helmet';

const MAX_PRODUCTS = 6;
const HIDDEN_BUNDLE_IDS = ['new'];

const BundlesList = ({
	bundles = [],
	updateBundleTitle,
	deleteBundle,
	pageContext,
}) => {
	const {productOptions, bulkDiscountPercentage} = pageContext;

	const bundleColumns = bundles.map(bundle => {
		const bundleProducts = bundle.productIds.map(_id =>
			find(productOptions, {_id}),
		);
		const bundleCents = reduce(
			bundleProducts,
			(totalCents, product) => {
				if (bundleProducts.length >= MAX_PRODUCTS) {
					const discountPercentage =
						(100 - bulkDiscountPercentage) / 100;
					return totalCents + product.cents * discountPercentage;
				} else {
					return totalCents + product.cents;
				}
			},
			0,
		);

		return (
			<div key={bundle.bundleId} className="col c6--md c4--lg">
				<BundleTile
					bundleId={bundle.bundleId}
					title={bundle.title}
					color={bundle.color}
					products={bundleProducts}
					totalCents={bundleCents}
					onSaveTitle={title =>
						updateBundleTitle(bundle.bundleId, title)
					}
					onRemove={() => deleteBundle(bundle.bundleId)}
				/>
			</div>
		);
	});

	const bundleGrid = bundles.length > 0 && (
		<div className="row gutter--vertical">{bundleColumns}</div>
	);

	const emptyState = bundles.length === 0 && (
		<p className="serif--lg serif--xxl--lg tc mt12 mb12">
			No cabinets yet.
		</p>
	);

	return (
		<div className="grid-container contained mt3 mb6 mb12--md bb--black pb8">
			<Helmet title="Saved Cabinets - Genexa" />
			<a aria-label="Site Map" href="https://www.genexa.com/sitemap.xml" class="full-hide">Sitemap</a> {/* Sitemap link accessibility */}
			<div className="row mb6">
				<div className="col c6--md mb2 mb0--md">
					<h1 className="tc tl--md serif--lg serif--xxl--lg">
						Saved Cabinets
					</h1>
				</div>
				<div className="col c6--md">
					<div className="df fdr jcc jce--md">
						<Link to="/cabinets/new" className="db button--primary"  aria-label="Create a New Cabinet">
							Create a New Cabinet
						</Link>
					</div>
				</div>
			</div>
			{bundleGrid}
			{emptyState}
		</div>
	);
};

const mapStateToProps = ({savedBundlesById}) => {
	const allBundles = values(savedBundlesById);
	const allVisibleBundles = filter(allBundles, b => {
		const isHiddenBundle = HIDDEN_BUNDLE_IDS.indexOf(b.bundleId) > -1;
		return !isHiddenBundle;
	});

	return {bundles: sortBy(allVisibleBundles, ['savedDate', 'bundleId'])};
};

const mapStoreToProps = ({deleteBundle, updateBundleTitle}) => ({
	deleteBundle,
	updateBundleTitle,
});

export default compose(
	clientSideComponent,
	connectToStore(mapStateToProps, mapStoreToProps),
)(BundlesList);
