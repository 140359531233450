import React from 'react';
import classNames from 'classnames';

export default ({image = {url: ''}, title, className, ...props}) => (
	<button
		{...props}
		className={classNames(
			className,
			'db pr bundle-builder__expanded-tile',
		)}
		title={title}
		aria-label={title}>
		<img
			alt={title}
			src={`${image.url}?h=300&auto=format`}
			className="db bundle-builder__expanded-tile-img"
		/>
		<img
			className="db pa bundle-builder__expanded-remove"
			src="/images/icon-bundle-remove.svg"
			alt="Remove bundle"
		/>
	</button>
);
