import React from 'react';
import {Router, Redirect} from '@reach/router';
import BundlesList from './BundlesList';
import BundleNew from './BundleNew';
import BundleEdit from './BundleEdit';
import clientSideComponent from '../middleware/clientSideComponent';
import Helmet from 'react-helmet';

const Route = ({children, path}) => <div>{children}</div>;

const Bundles = ({pageContext}) =>{
	return  (
		<div>
			<Helmet title='Saved Cabinets - Genexa'/>
			<Router>
				<Redirect from="/cabinets" to="/cabinets/new" />
				<Redirect from="/bundles/new" to="/cabinets/new" />
				<Redirect from="/bundles" to="/cabinets/saved" />

				<Route path="/cabinets">
					<BundleNew pageContext={pageContext} path="/new" />
					<BundlesList pageContext={pageContext} path="/saved" />
					<BundleEdit pageContext={pageContext} path="/:bundleId" />
				</Route>
			</Router>
		</div>
	);
}

export default clientSideComponent(Bundles);
