import React, {useState} from 'react';
import centsToPriceNoTrailingZeros from 'magic-tricks/lib/centsToPriceNoTrailingZeros';
import InlineForm from './InlineForm';
import {Link} from 'gatsby';

export default ({
	bundleId,
	title,
	color,
	products = [],
	totalCents = 0,
	onSaveTitle,
	onRemove,
}) => {
	const [showTitleInput, setShowTitleInput] = useState(false);

	const onClickRemove = () => {
		if (!confirm('Are you sure you want to delete this bundle?')) return;

		onRemove();
	};


	const titleTrigger = !showTitleInput && (
		<button
			title={title}
			aria-label={title}
			onClick={() => setShowTitleInput(true)}
			className="db sans--md sans--lg--lg fw--800">
			{title}
		</button>
	);

	const titleForm = showTitleInput && (
		<InlineForm
			initialValue={title}
			onSubmit={title => {
				setShowTitleInput(false);
				onSaveTitle(title);
			}}
			submitCta="Save"
			placeholder="Your Medicine Cabinet"
			className="invert"
		/>
	);

	return (
		<div className="bg--gray-bg p2">
			<div className="hide--md mb2">
				{titleTrigger}
				{titleForm}
			</div>

			<div className="row bundle-builder__modal-row fdr">
				<div className="col c6">
					<div
						className="y of--hidden df jcc aic pt8 pb8 pt10--lg pb10--lg"
						style={{backgroundColor: color}}>
						<img
							className="bundle-builder__modal-tile-logo db"
							src="/images/logo-tile.png"
							alt="Logo"
						/>
					</div>
				</div>
				<div className="col c6 bundle-builder__modal-col fdc jcb">
					<div>
						<div className="show--md mb2">
							{titleTrigger}
							{titleForm}
						</div>
						<p className="mb1">
							{centsToPriceNoTrailingZeros(totalCents)}
						</p>
						<ul className="mb1 sans--xs bundle-builder__modal-product-list">
							{products.map((product, index) => (
								<li key={index}>{product.title}</li>
							))}
						</ul>
					</div>

					<div className="df fdr">
						<Link
							aria-label="Edit"
							to={`/cabinets/${bundleId}`}
							className="db link--underline-invert-small mr2">
							Edit
						</Link>
						<button
							title="Remove"
							aria-label="Remove"
							onClick={onClickRemove}
							className="db link--underline-invert-small">
							Remove
						</button>
					</div>
				</div>
			</div>
		</div>
	);
};
